import { Box, Typography } from '@mui/material';
import * as icons from '@mui/icons-material';

import styles from './icon-animation.module.css';

const IconAnimation = ({ message, icon, color }) => {
  const Component = icons[icon];
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Component className={styles.IconAnimation} style={{ fontSize: '100px', color }} />
      <Typography variant="h5" className={styles.fadeInText}>
        {message}
      </Typography>
    </Box>
  );
};

export default IconAnimation;
