import { Box, Paper, Grid2 as Grid } from '@mui/material';
import { ImageNotSupported } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { formatPhoneNumber, setResizedBusinessLogo } from '../../helpers';
import IconAnimation from '../../components/icon-animation';
import PageLoader from '../../components/page-loader';
import Footer from '../../components/footer';

import styles from './index.module.css';

const Expired = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const res = await fetch(`/api/expired/otp/${clientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (data.client) {
          setClient(data.client);
          if (data.client.business_logo) {
            setResizedBusinessLogo(data.client.business_logo, 200, setClient);
          }
        }
      }

      setLoading(false);
    };

    init();
  }, [clientId]);

  if (loading) {
    return <PageLoader isRequesting={true} />;
  }

  return (
    <main className={styles.main}>
      <header className={styles.header} id="header">
        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
          <Grid size={6}>
            {client.businessLogo ? (
              <img
                alt={client.businessName}
                src={client.businessLogo}
                title={client.businessName}
                style={{ maxWidth: client.businessLogoWidth }}
              />
            ) : (
              <ImageNotSupported fontSize="large" color="disabled" />
            )}
          </Grid>
          <Grid size={6}>
            {client.phone && (
              <h2>
                <a href={`tel:${client.phone}`}>
                  <PhoneIcon /> {formatPhoneNumber(client.phone)}
                </a>
              </h2>
            )}
          </Grid>
        </Grid>
      </header>
      <Paper elevation={2} className={styles.paper}>
        <Box sx={{ width: '100%' }}>
          <div className={styles.formContainer}>
            <>
              <IconAnimation message="Patient Link Expired" icon="WarningAmber" color="var(--client-primary-color)" />
              <p className={styles.mainDescription}>
                It seems the page you're trying to access has expired. For assistance with completing your patient intake forms,
                please contact our office at <a href={`tel:${client.phone}`}>{formatPhoneNumber(client.phone)}</a>
                . Thank you for your understanding.
                <br />
                <br />
                <strong>We're here to help if you need anything!</strong>
              </p>
            </>
          </div>
        </Box>
      </Paper>
      <Footer />
    </main>
  );
};

export default Expired;
