import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useRef } from 'react';

import styles from './fields.module.css';

const RadioField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const radioRefs = useRef({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const focusRadioGroup = () => {
    const firstRadio = Object.values(radioRefs.current)[0];
    if (firstRadio) {
      firstRadio.focus();
    }
  };

  refs.current[field.name] = { focus: focusRadioGroup };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <FormControl fullWidth error={!!errors[field.name]} sx={{ maxWidth: 600, textAlign: 'center' }}>
        <FormLabel id={`radio-label-${field.name}`} sx={{ color: 'black' }}>
          {field.label}
          {field.required && <span className={styles.requiredAsterisk}>&nbsp;*</span>}
        </FormLabel>
        {field.helperText && !!errors[field.name] && <i className={styles.helperText}>{field.helperText}</i>}
        <RadioGroup
          row
          id={field.name}
          key={field.name}
          name={field.name}
          value={formData[field.name]}
          onChange={handleInputChange}
          sx={{ justifyContent: 'center' }}
          labelId={`radio-label-${field.name}`}
        >
          {field.options.map((o) => (
            <FormControlLabel
              key={o.value}
              label={o.label}
              value={o.value}
              control={
                <Radio
                  inputRef={(el) => {
                    if (el) {
                      radioRefs.current[o.value] = el;
                    } else {
                      delete radioRefs.current[o.value];
                    }
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioField;
