import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Paper,
  Input,
  Button,
  Select,
  Snackbar,
  MenuItem,
  TextField,
  Container,
  IconButton,
  InputLabel,
  FormControl,
  Grid2 as Grid,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { ImageNotSupported, Visibility, VisibilityOff } from '@mui/icons-material';

import { autoFormatPhoneNumber, convertToBase64, setResizedBusinessLogo, states } from '../../../../helpers';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Header from '../../../../components/header';
import Footer from '../../../../components/footer';
import Loader from '../../../../components/loader';

import styles from '../index.module.css';

const ProfileSettings = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ passwordMismatch: false });
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({ password: '', confirmPassword: '' });

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});

  const token = sessionStorage.getItem('authToken');
  const clientId = sessionStorage.getItem('clientId');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const changedValue = name === 'phoneNumber' ? autoFormatPhoneNumber(value) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: changedValue,
    }));
  };

  const handlePasswordChange = (e) => {
    setErrors({ passwordMismatch: false });
    const { name, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const base64 = await convertToBase64(file);
      setResizedBusinessLogo(base64, 275, setFormData);
    }
  };

  const handleProfileSubmit = async () => {
    await fetch(`/api/clients/${clientId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData }),
    });
    setToastOpen(true);
    setToastMessage({ label: 'Successfully updated company profile!', severity: 'success' });
  };

  const handlePasswordSubmit = async () => {
    await fetch(`/api/clients/${clientId}/password`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: passwordData.password }),
    });

    setToastOpen(true);
    setToastMessage({ label: 'Successfully updated password, logging out...', severity: 'success' });
    setPasswordData({ password: '', confirmPassword: '' });
    setTimeout(() => navigate('/admin/logout'), 3000);
  };

  useEffect(() => {
    const getClient = async () => {
      const res = await fetch(`/api/clients/${clientId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (data.client && Object.keys(data.client).length > 0) {
          setFormData({
            businessName: data.client.business_name,
            firstName: data.client.first_name,
            lastName: data.client.last_name,
            email: data.client.email,
            phoneNumber: data.client.phone,
            address: data.client.address_line1,
            addressTwo: data.client.address_line2,
            city: data.client.city,
            state: data.client.state,
            postalCode: data.client.postal_code,
          });
          if (data.client.business_logo) {
            setResizedBusinessLogo(data.client.business_logo, 275, setFormData);
          }
        }
      }

      setLoading(false);
    };

    getClient();
  }, [token, clientId]);

  const handleOpenProfileDialog = (e) => {
    e.preventDefault();
    setProfileDialogOpen(true);
  };
  const handleCloseProfileDialog = () => {
    setProfileDialogOpen(false);
  };
  const handleProfileConfirm = () => {
    setProfileDialogOpen(false);
    handleProfileSubmit();
  };

  const handleOpenPasswordDialog = (e) => {
    e.preventDefault();
    if (passwordData.password !== passwordData.confirmPassword) {
      setErrors({ passwordMismatch: true });
      return;
    }

    setPasswordDialogOpen(true);
  };
  const handleClosePasswordDialog = () => {
    setPasswordDialogOpen(false);
  };
  const handlePasswordConfirm = () => {
    setPasswordDialogOpen(false);
    handlePasswordSubmit();
  };

  return (
    <main className={styles.main}>
      <Header title="Profile Settings" setToastOpen={setToastOpen} setToastMessage={setToastMessage} />
      <Paper elevation={2} className={styles.paper}>
        <section className={styles.section}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Container>
                <h2>Company Profile</h2>
                <form onSubmit={handleOpenProfileDialog}>
                  <Grid container spacing={2}>
                    <Grid size={12} marginBottom={2}>
                      <TextField
                        label="Business Name"
                        name="businessName"
                        fullWidth
                        value={formData.businessName}
                        onChange={handleInputChange}
                        required
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={12} textAlign="center">
                      {formData.businessLogo ? (
                        <img
                          src={formData.businessLogo}
                          title={formData.businessName}
                          alt={formData.businessName}
                          width={formData.businessLogoWidth}
                          height={formData.businessLogoHeight}
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      ) : (
                        <ImageNotSupported fontSize="large" color="disabled" />
                      )}
                    </Grid>
                    <Grid size={12} marginBottom={2}>
                      <Input
                        type="file"
                        inputProps={{ accept: 'image/*' }}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="file-input"
                      />
                      <label htmlFor="file-input">
                        <Button variant="outlined" color="primary" component="span" fullWidth>
                          Upload Business Logo
                        </Button>
                      </label>
                    </Grid>
                    <Grid container spacing={2} width="100%">
                      <Grid size={6}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          fullWidth
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid size={6}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          fullWidth
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid size={12}>
                      <TextField
                        label="Address"
                        name="address"
                        fullWidth
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={12}>
                      <TextField
                        label="Address Line 2"
                        name="addressTwo"
                        fullWidth
                        value={formData.addressTwo}
                        onChange={handleInputChange}
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={12}>
                      <TextField
                        label="City"
                        name="city"
                        fullWidth
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid container spacing={2} width="100%">
                      <Grid size={6}>
                        <FormControl fullWidth>
                          <InputLabel id="state-select-label">
                            State<span className={styles.requiredAsterisk}>&nbsp;*</span>
                          </InputLabel>
                          <Select
                            labelId="state-select-label"
                            id="state-select"
                            value={formData.state}
                            label="State"
                            name="state"
                            onChange={handleInputChange}
                            required
                          >
                            {states.map((state) => (
                              <MenuItem key={state.value} value={state.value}>
                                {state.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <TextField
                          label="Postal Code"
                          name="postalCode"
                          fullWidth
                          value={formData.postalCode}
                          onChange={handleInputChange}
                          required
                          slotProps={{
                            htmlInput: {
                              pattern: '^\\d{5}$',
                              maxLength: 5,
                            },
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid size={12}>
                      <TextField
                        label="Email"
                        type="email"
                        name="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        slotProps={{
                          htmlInput: {
                            pattern: '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$',
                          },
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={12}>
                      <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        type="tel"
                        fullWidth
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        required
                        slotProps={{
                          htmlInput: {
                            pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                            maxLength: 12,
                          },
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={12}>
                      <Button type="submit" variant="contained" color="primary" fullWidth>
                        Save Profile
                      </Button>
                    </Grid>
                  </Grid>
                  <ConfirmDialog
                    open={profileDialogOpen}
                    onClose={handleCloseProfileDialog}
                    onConfirm={handleProfileConfirm}
                    title="Confirm Profile Update"
                    message="Are you sure you want to update your profile?"
                  />
                </form>
              </Container>

              <Container>
                <h2>Change Password</h2>
                <form onSubmit={handleOpenPasswordDialog}>
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="password">New Password</InputLabel>
                        <OutlinedInput
                          id="password"
                          name="password"
                          label="New Password"
                          type={showPassword ? 'text' : 'password'}
                          value={passwordData.password}
                          onChange={handlePasswordChange}
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid size={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                          id="confirm-password"
                          name="confirmPassword"
                          label="Confirm Password"
                          type={showConfirmPassword ? 'text' : 'password'}
                          value={passwordData.confirmPassword}
                          onChange={handlePasswordChange}
                          required
                          error={errors.passwordMismatch}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirm password visibility"
                                onClick={handleClickShowConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    {errors.passwordMismatch ? <p>Passwords do not match</p> : <p>&nbsp;</p>}
                    <Grid size={12}>
                      <Button type="submit" variant="contained" color="primary" fullWidth>
                        Change Password
                      </Button>
                    </Grid>
                  </Grid>
                  <ConfirmDialog
                    open={passwordDialogOpen}
                    onClose={handleClosePasswordDialog}
                    onConfirm={handlePasswordConfirm}
                    title="Confirm Password Change"
                    message="Are you sure you want to update your password?"
                  />
                </form>
              </Container>
            </>
          )}
        </section>
      </Paper>
      <Footer />
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastMessage.severity} sx={{ width: '100%' }}>
          {toastMessage.label}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default ProfileSettings;
