import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import styles from './fields.module.css';

const SelectField = ({ field, formData, setFormData, setErrors, refs, errors, dynamicState }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`select-label-${field.name}`}>
        {field.label}
        {field.required && <span className={styles.requiredAsterisk}>&nbsp;*</span>}
      </InputLabel>
      <Select
        fullWidth
        id={field.name}
        key={field.name}
        name={field.name}
        label={field.label}
        value={formData[field.name]}
        onChange={handleInputChange}
        error={!!errors[field.name]}
        inputRef={refs.current[field.name]}
        labelId={`select-label-${field.name}`}
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
      >
        <MenuItem key="default" value="" disabled>
          Please Select
        </MenuItem>
        {!field.required && !field.hideOther && (
          <MenuItem key="Other" value="Other">
            Other
          </MenuItem>
        )}
        {field.optionsByDynamicStateKey &&
          dynamicState[field.optionsByDynamicStateKey].map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        {!field.optionsByDynamicStateKey &&
          field.options.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
