import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  typography: {
    fontFamily: 'var(--font-roboto)',
  },
  palette: {
    primary: {
      main: '#254f38',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'rgb(211, 47, 47)' },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { color: 'rgb(211, 47, 47)' },
      },
    },
  },
});

export const clientTheme = createTheme({
  typography: {
    fontFamily: 'var(--font-roboto)',
  },
  palette: {
    primary: {
      main: '#a33896',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'rgb(211, 47, 47)' },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { color: 'rgb(211, 47, 47)' },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            backgroundColor: 'var(--client-secondary-color)',
            '&:hover': {
              backgroundColor: 'var(--client-secondary-hover-color)',
            },
          }),
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'var(--client-secondary-color)',
          '&.Mui-completed': {
            color: 'var(--client-accent-color)',
          },
          '&.Mui-active': {
            color: 'var(--client-accent-color)',
            '& .MuiStepIcon-text': {
              fontWeight: 'bold',
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          cursor: 'pointer !important',
        },
        label: {
          color: '#FFFFFF !important',
          fontSize: '14px',
          cursor: 'pointer',
          '@media (max-width:430px)': {
            fontSize: '10px',
          },
          '@media (max-width:390px)': {
            fontSize: '8px',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: 'var(--client-accent-color)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--client-secondary-color)',
          },
        },
      },
    },
  },
});
