import { Paper, IconButton, FormControl, TextField, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';

import Footer from '../../../components/footer';

import styles from './index.module.css';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email || email.trim().length < 2 || !password || password.trim().length < 2) {
      setRequestError('Email & Password Required');
      setLoading(false);
      return;
    }

    const res = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (res.ok) {
      const data = await res.json();
      sessionStorage.setItem('authToken', data.token);
      sessionStorage.setItem('clientId', data.clientId);
      navigate('/admin/dashboard');
    } else {
      const { error } = await res.json();
      setRequestError(error);
      setLoading(false);
    }
  };

  if (sessionStorage.getItem('authToken')) {
    return <Navigate to="/admin/dashboard" />;
  }

  return (
    <main className={styles.main}>
      <img src="/health-of-your-space.png" alt="Health of Your Space" className={styles.logo} width={188} height={50} />

      <Paper elevation={2} className={styles.paper}>
        <h1>Dashboard Login</h1>
        <form onSubmit={handleSubmit}>
          <FormControl variant="outlined" className={styles.form}>
            <FormControl>
              <TextField
                id="email"
                name="email"
                label="Email"
                type="text"
                error={!!requestError}
                value={email}
                onChange={(e) => {
                  setRequestError(null);
                  setEmail(e.target.value);
                }}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                error={!!requestError}
                onChange={(e) => {
                  setRequestError(null);
                  setPassword(e.target.value);
                }}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {requestError ? <p>{requestError}</p> : <p>&nbsp;</p>}
            <LoadingButton type="submit" loading={loading} disabled={loading} variant="contained" loadingPosition="end">
              Login
            </LoadingButton>
          </FormControl>
        </form>
      </Paper>
      <Footer />
    </main>
  );
};

export default Login;
