import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider, Box } from '@mui/material';
import { Close } from '@mui/icons-material';

import styles from './confirm-dialog.module.css';

const ConfirmDialog = ({
  open,
  title,
  message,
  onClose,
  onAction,
  onConfirm,
  closeLabel,
  actionLabel,
  description,
  confirmLabel,
  hideCloseBtn,
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-title" aria-describedby="confirm-message">
      <DialogTitle id="confirm-title" className={styles.modalTitle}>
        {title} <Close onClick={onClose} sx={{ cursor: 'pointer' }} />
      </DialogTitle>
      <DialogContent>
        {message && (
          <DialogContentText id="confirm-message" sx={description && { marginBottom: '20px' }}>
            {message}
          </DialogContentText>
        )}
        {description && (
          <DialogContentText id="confirm-description" className={styles.description}>
            {description}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: '20px' }}>
        <Box display="flex" justifyContent="space-between" {...(onAction ? { width: '100%' } : {})}>
          {onAction && (
            <Button onClick={onAction} color="primary" variant="outlined">
              {actionLabel || 'Action'}
            </Button>
          )}
          <Box>
            {onClose && !hideCloseBtn && (
              <Button onClick={onClose} color="primary">
                {closeLabel || 'Cancel'}
              </Button>
            )}
            {onConfirm && (
              <Button onClick={onConfirm} color="primary" variant="contained" sx={{ marginLeft: '8px' }}>
                {confirmLabel || 'Confirm'}
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
