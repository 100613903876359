import { DatePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';

import { getDynamicRequired } from '../../helpers';

const DateField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const [date, setDate] = useState(
    moment(formData[field.name], 'YYYY-MM-DD', true).isValid() ? moment(formData[field.name]) : null,
  );

  const handleDateChange = (dateInput) => {
    setDate(dateInput);
    const validDate = moment(dateInput, 'YYYY-MM-DD', true).isValid() && moment(dateInput).year() >= 1900;
    const dateValue = validDate ? moment(dateInput).format('YYYY-MM-DD') : null;
    setFormData((prevState) => ({
      ...prevState,
      [field.name]: dateValue,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field.name]: false }));
  };

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={field.marginTop}
      marginBottom={field.marginBottom}
    >
      <DatePicker
        openTo="year"
        disableFuture
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!errors[field.name],
            inputRef: refs.current[field.name],
            helperText: !!errors[field.name] && field.helperText,
            required: getDynamicRequired(formData, field.required),
          },
        }}
        value={date}
        sx={field.sx}
        id={field.name}
        key={field.name}
        name={field.name}
        label={field.label}
        onChange={handleDateChange}
        views={['year', 'month', 'day']}
      />
    </Box>
  );
};

export default DateField;
