import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Divider,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import { autoMaxOtpLength } from '../../helpers';

import styles from './index.module.css';

const OtpModal = ({ open, otpError, createOtp, otpMessage, setOtpError, onModalSubmit }) => {
  const [code, setCode] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onModalSubmit(code);
  };

  const handleCodeChange = (e) => {
    setOtpError(false);
    setCode(autoMaxOtpLength(e.target.value));
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{
        backdrop: {
          onClick: (e) => e.stopPropagation(),
        },
      }}
    >
      <form onSubmit={handleFormSubmit}>
        <DialogTitle id="modal-title" className={styles.modalTitle}>
          Secured Patient Intake Form
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="modal-description"
            className={styles.modalMessage}
            dangerouslySetInnerHTML={{ __html: otpMessage }}
          />
          <h3 className={styles.modalHeader}>One-Time Passcode</h3>
          <TextField
            fullWidth
            name="code"
            value={code}
            label="Code"
            type="number"
            margin="normal"
            required={true}
            error={otpError}
            onChange={handleCodeChange}
            slotProps={{
              htmlInput: {
                minLength: 6,
                maxLength: 6,
                style: { textAlign: 'center' },
              },
              inputLabel: {
                shrink: true,
              },
            }}
          />
          <p className={styles.note} onClick={createOtp}>
            Didn't receive the code? <strong>Resend</strong>
          </p>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: '20px' }}>
          <Box display="flex" justifyContent="center" width="100%">
            <Box>
              <Button type="submit" color="primary" variant="contained" className={styles.submitButton}>
                Submit
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OtpModal;
