import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom/client';
import React, { useMemo } from 'react';

import ProfileSettings from './pages/admin/dashboard/profile-settings';
import ApiManager from './pages/admin/dashboard/api-manager';
import ProtectedRoute from './components/protected-route';
import { appTheme, clientTheme } from './theme';
import Dashboard from './pages/admin/dashboard';
import Logout from './pages/admin/logout';
import NotFound from './pages/not-found';
import Login from './pages/admin/login';
import Expired from './pages/expired';
import Intake from './pages/intake';

import './index.css';

const titleSuffix = 'HoYS Platform';
const titleMap = {
  '/admin/dashboard/profile-settings': `Profile Settings - ${titleSuffix}`,
  '/admin/dashboard/api-manager': `API Manager - ${titleSuffix}`,
  '/admin/dashboard': `Dashboard - ${titleSuffix}`,
  '/admin/login': `Login - ${titleSuffix}`,
};

const useThemeBasedOnRoute = () => {
  const location = useLocation();

  return useMemo(() => {
    const { pathname } = location;

    // Determine theme
    const isClientPage = pathname.includes('/clients/') || pathname.includes('/expired-otp/');
    const activeTheme = isClientPage ? clientTheme : appTheme;

    // Determine title
    const dynamicTitle = titleMap[pathname] || (isClientPage ? 'Patient Intake Form' : `Page Not Found - ${titleSuffix}`);
    document.title = dynamicTitle;
    return activeTheme;
  }, [location]);
};

const HoysPlatformClient = () => {
  const activeTheme = useThemeBasedOnRoute();

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute roles={['client']} key="admin-dashboard">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/dashboard/profile-settings"
          element={
            <ProtectedRoute roles={['client']} key="admin-dashboard-profile-settings">
              <ProfileSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/dashboard/api-manager"
          element={
            <ProtectedRoute roles={['admin']} key="admin-dashboard-api-manager">
              <ApiManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientId"
          element={
            <ProtectedRoute roles={['user']} otp={true} key="user-intake">
              <Intake />
            </ProtectedRoute>
          }
        />
        <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/expired-otp/:clientId" element={<Expired />} />
        <Route path="/admin/logout" element={<Logout />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <HoysPlatformClient />
    </Router>
  </React.StrictMode>,
);
